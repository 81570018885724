import { render, staticRenderFns } from "./NewPost.vue?vue&type=template&id=5f66606e"
import script from "./NewPost.vue?vue&type=script&lang=js"
export * from "./NewPost.vue?vue&type=script&lang=js"
import style0 from "./NewPost.vue?vue&type=style&index=0&id=5f66606e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CImage: require('@chakra-ui/vue').CImage, CText: require('@chakra-ui/vue').CText, CFlex: require('@chakra-ui/vue').CFlex, CPopoverTrigger: require('@chakra-ui/vue').CPopoverTrigger, CPseudoBox: require('@chakra-ui/vue').CPseudoBox, CListItem: require('@chakra-ui/vue').CListItem, CList: require('@chakra-ui/vue').CList, CPopoverBody: require('@chakra-ui/vue').CPopoverBody, CPopoverContent: require('@chakra-ui/vue').CPopoverContent, CPopover: require('@chakra-ui/vue').CPopover, CIcon: require('@chakra-ui/vue').CIcon, CButton: require('@chakra-ui/vue').CButton, CModalHeader: require('@chakra-ui/vue').CModalHeader, CModalCloseButton: require('@chakra-ui/vue').CModalCloseButton, CBox: require('@chakra-ui/vue').CBox, CMenuButton: require('@chakra-ui/vue').CMenuButton, CMenuList: require('@chakra-ui/vue').CMenuList, CMenu: require('@chakra-ui/vue').CMenu, CIconButton: require('@chakra-ui/vue').CIconButton, CSpinner: require('@chakra-ui/vue').CSpinner, CModalBody: require('@chakra-ui/vue').CModalBody, CModalFooter: require('@chakra-ui/vue').CModalFooter, CModalContent: require('@chakra-ui/vue').CModalContent, CModalOverlay: require('@chakra-ui/vue').CModalOverlay, CModal: require('@chakra-ui/vue').CModal})
